import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addResume } from "../../Api";

function Resume() {
  const [files, setFiles] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const handleChange = (e) => {
    // setFiles(e.traget.file[0]);
    // console.log(e.target.files[0]);
    setFiles(e.target.files[0]);
    setIsFilePicked(true);
    // ;
  };
  const navigate = useNavigate();
  const submit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("thumbnail", files);

    let response = await addResume(formData);
    console.log(response.data);
    if (response.data) {
      alert("resume sucessfully uploded");
      navigate("/user-dashbord");
    }
  };
  // console.log();

  return (
    <div className="flex w-[100%] h-[90vh] items-center justify-center">
      <form className="w-full text-center m-auto" onSubmit={submit}>
        <div className="w-[40%] m-auto">
          <div
            className={`w-full m-auto mb-4 ${
              isFilePicked ? "opacity-50 cursor-not-allowed" : ""
            } `}
          >
            <label className="block text-sm font-medium text-gray-700">
              Resume
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="True"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600">
                  <label
                    htnlfor="file-upload"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span>Upload a file</span>
                    {!isFilePicked && (
                      <input
                        id="file-upload"
                        name="thumbnail"
                        type="file"
                        className="sr-only cursor-not-allowed"
                        required="true"
                        onChange={(e) => handleChange(e)}
                      />
                    )}
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">JPG, PDF up to 10MB</p>
              </div>
            </div>
          </div>
          {isFilePicked && <p>{files.name}</p>}
          <div className="py-2">
            <button
              type="submit"
              className="border-2 border-gray-100 focus:outline-none bg-purple-600 text-white font-bold tracking-wider block w-full p-2 rounded-lg focus:border-gray-700 hover:bg-purple-700 w-[70%] m-auto"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Resume;
