import React, { useEffect, useState } from "react";
import { applicationStatus, getAllUsersDetails } from "../../Api";

function Dashbord() {
  const [details, setDetails] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let res = await getAllUsersDetails();
    setDetails(res.data);
  };
  const shortlisted = async (data, id) => {
    let res = await applicationStatus(data, id);
    // console.log(res.data);
    if (res.data) {
      alert("submitted");
    }
  };

  return (
    <>
      <div className="bg-teal-600 h-screen flex gap-10  overflow-hidden">
        <div className="w-64 inline-block">
          <aside
            className="w-full pt-10 pl-10 h-full sticky "
            aria-label="Sidebar "
          >
            <div className="overflow-y-auto py-4 px-3 bg-gray-50 rounded dark:bg-gray-800">
              <ul className="space-y-2">
                <li>
                  <a
                    href="#"
                    className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                      <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                    </svg>
                    <span className="ml-3">Dashboard</span>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <svg
                      aria-hidden="true"
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Sign In
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <svg
                      aria-hidden="true"
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Sign Up
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </aside>
        </div>

        <div className="w-[70%] overflow-y-scroll mt-6">
          {details.map((item, index) => {
            const {
              id,
              experience,
              Location,
              email,
              phone,
              qualification,
              thumbnail_url,
              name,
              user_id,
            } = item;
            console.log(user_id);
            return (
              <div
                className="w-[97%] shadow-lg bg-slate-50  my-6 h-28 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                key={id}
              >
                <div className="w-full flex items-center justify-center h-full gap-10 ">
                  <div className="information flex gap-10">
                    <div>
                      <p>
                        <span className="font-bold"> Name : </span>
                        <span className="font-semibold text-gray-800">
                          {name}
                        </span>
                      </p>

                      <p>
                        <span className="font-bold"> Email : </span>
                        <span className="font-semibold text-gray-800">
                          {email}
                        </span>
                      </p>
                      <p className="flex items-center gap-y-64">
                        <span className="font-bold"> View Doc : </span>
                        {/* <span className="font-bold cursor-pointer text-purple-800">
                          {thumbnail_url}
                        </span> */}

                        {thumbnail_url.includes("/undefined") ? (
                          <>
                            <span> Doc not available</span>
                          </>
                        ) : (
                          <>
                            {" "}
                            <a href={thumbnail_url} target="_blank">
                              <span className="border-2 border-gray-100 focus:outline-none bg-purple-600 text-white font-bold tracking-wider block  px-2 py-1 rounded-lg focus:border-gray-700 hover:bg-purple-700 ">
                                View
                              </span>
                            </a>
                          </>
                        )}
                      </p>
                    </div>
                    <div>
                      <p>
                        <span className="font-bold"> Qualification : </span>
                        <span className="font-semibold text-gray-800">
                          {qualification}
                        </span>
                      </p>

                      <p>
                        <span className="font-bold"> Experience : </span>
                        <span className="font-semibold text-gray-800">
                          {experience}
                        </span>
                      </p>
                    </div>

                    <div>
                      <p>
                        <span className="font-bold"> Phone : </span>
                        <span className="font-semibold text-gray-800">
                          {phone}
                        </span>
                      </p>

                      <p>
                        <span className="font-bold"> Location : </span>
                        <span className="font-semibold text-gray-800">
                          {Location}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="btn flex gap-2">
                    <button
                      className="border-2 border-gray-100 focus:outline-none bg-purple-600 text-white font-bold tracking-wider block  p-2 rounded-lg focus:border-gray-700 hover:bg-purple-700 w-20"
                      onClick={() => shortlisted(true, user_id)}
                    >
                      Accept{" "}
                    </button>

                    <button
                      className="border-2 border-gray-100 focus:outline-none bg-red-600 text-white font-bold tracking-wider block  p-2 rounded-lg focus:border-gray-700 hover:bg-red-700 w-20"
                      onClick={() => shortlisted(false, user_id)}
                    >
                      Reject{" "}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Dashbord;
