import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../Api";
// import Signup from "./Component/Signup";
// import { ToastContainer, toast } from "react-toastify";
function Login() {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  let navigation = useNavigate();
  const submit = async (e) => {
    e.preventDefault();
    let res = await login(data);
    if (res.data) {
      if (res.data.role === "admin") {
        navigation("/dashbord");
      } else if (res.data.role === "user") {
        navigation("/user-dashbord");
      }
      localStorage.setItem("logdinUserDetails", JSON.stringify(res.data));
    }
  };

  return (
    <>
      <section className="min-h-screen flex flex-col">
        <div className="flex flex-1 items-center justify-center">
          <div className="rounded-lg sm:border-2 px-4 lg:px-24 py-16 lg:max-w-xl sm:max-w-md w-full text-center">
            <form className="text-center" onSubmit={submit}>
              <h1 className="font-bold tracking-wider text-3xl mb-8 w-full text-gray-600">
                Sign in
              </h1>
              <div className="py-2 text-left">
                <input
                  type="email"
                  className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700 "
                  placeholder="Email"
                  onChange={handleChange}
                  name="email"
                  required="true"
                />
              </div>
              <div className="py-2 text-left">
                <input
                  type="password"
                  className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700 "
                  placeholder="Password"
                  onChange={handleChange}
                  name="password"
                  required="true"
                />
              </div>
              <div className="py-2">
                <button
                  type="submit"
                  className="border-2 border-gray-100 focus:outline-none bg-purple-600 text-white font-bold tracking-wider block w-full p-2 rounded-lg focus:border-gray-700 hover:bg-purple-700"
                >
                  Sign In
                </button>
              </div>
            </form>

            <div className="text-center mt-12">
              <span>Don't have an account?</span>
              <Link to="/sign-up">
                {" "}
                <span className="text-blue-600">Create One</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
