import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../../Api";

function Signup() {
  const navigate = useNavigate();
  let initialVAl = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "user",
  };
  const [data, setData] = useState(initialVAl);
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let res = await register(data);
    // console.log(res.data);
    navigate("/details-form");
  };
  //   console.log(data);
  return (
    <>
      {" "}
      <section className="min-h-screen flex flex-col">
        <div className="flex flex-1 items-center justify-center ">
          <div className="rounded-lg sm:border-2 px-4  text-center py-10">
            <form className="text-center" onSubmit={handleSubmit}>
              <h1 className="font-bold tracking-wider text-3xl mb-8 w-full text-gray-600">
                Sign Up
              </h1>
              <div className="flex gap-5">
                <div className="py-2 text-left w-[400px]">
                  <input
                    type="text"
                    className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700 "
                    placeholder="FirstName"
                    onChange={handleChange}
                    required="true"
                    name="firstName"
                  />
                </div>
                <div className="py-2 text-left w-[400px]">
                  <input
                    type="text"
                    className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700 "
                    placeholder="LastName"
                    onChange={handleChange}
                    name="lastName"
                    required="true"
                  />
                </div>
              </div>
              <div className="flex gap-5">
                <div className="py-2 text-left w-[400px]">
                  <input
                    type="Email"
                    className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700 "
                    placeholder="email"
                    onChange={handleChange}
                    name="email"
                    required="true"
                  />
                </div>
                <div className="py-2 text-left w-[400px]">
                  <input
                    type="password"
                    className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700 "
                    placeholder="Password"
                    onChange={handleChange}
                    name="password"
                    required="true"
                  />
                </div>
              </div>
              {/* <div className="flex gap-5">
                <div className="py-2 text-left w-[400px]">
                  <select
                    name="role"
                    id="cars"
                    className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700 "
                    onChange={handleChange}
                  >
                    <option value="user">User</option>
                    <option value="admin">Admin</option>
                  </select>
                </div>
              </div> */}
              <div className="py-2">
                <button
                  type="submit"
                  className="border-2 border-gray-100 focus:outline-none bg-purple-600 text-white font-bold tracking-wider block w-full p-2 rounded-lg focus:border-gray-700 hover:bg-purple-700 w-[70%] m-auto"
                >
                  Sign Up
                </button>
              </div>
            </form>

            <div className="text-center mt-12">
              <span>Already have an account?</span>
              <Link to="/">
                {" "}
                <span className="text-blue-600">Login</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Signup;
