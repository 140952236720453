import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logedUserDetails, user_adminDetails } from "../../Api";

function UserDashbord() {
  let [res, setRes] = useState({});
  let [data, setData] = useState([]);
  let [Approval, setApproval] = useState();
  let [url, setUrl] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    setRes(JSON.parse(localStorage.getItem("userData")));
  }, []);

  let { email, firstName, lastName, role, _id } = res;

  useEffect(() => {
    if (res._id) {
      getResponse();
      approved();
    }
  }, [res]);

  const getResponse = async () => {
    let res = await user_adminDetails(_id);
    setData(res.data);
    setUrl(res.data[0].thumbnail_url);
    console.log(res.data[0]);
  };

  const approved = async () => {
    let res = await logedUserDetails(_id);
    setApproval(res.data.Approval);
  };

  const download = (source) => {
    const fileName = source.split("/").pop();
    var el = document.createElement("a");
    el.setAttribute("href", source);
    el.setAttribute("download", fileName);
    document.body.appendChild(el);
    console.log("Piyush");
    el.click();
    el.remove();
  };
  return (
    <>
      <div className="bg-teal-600 max-h-screen flex gap-10  overflow-hidden h-screen">
        <div className="w-64 inline-block  ">
          <aside
            className="w-full pt-10 pl-10 h-full sticky "
            aria-label="Sidebar "
          >
            <div className="overflow-y-auto py-4 px-3 bg-gray-50 rounded dark:bg-gray-800">
              <ul className="space-y-2">
                <li>
                  <a
                    href="#"
                    className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                      <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                    </svg>
                    <span className="ml-3">Dashboard</span>
                  </a>
                </li>

                <li>
                  <Link
                    to="/resume"
                    className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <svg
                      aria-hidden="true"
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap capitalize">
                      upload document
                    </span>
                  </Link>
                </li>
                {url ? (
                  <>
                    {!url.includes("/undefined") && (
                      <li>
                        <a
                          href={url}
                          className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                          target={"_blank"}
                        >
                          <svg
                            aria-hidden="true"
                            className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="flex-1 ml-3 whitespace-nowrap capitalize">
                            view document
                          </span>
                        </a>
                      </li>
                    )}
                  </>
                ) : (
                  ""
                )}

                {url ? (
                  <>
                    {!url.includes("/undefined") && (
                      <li>
                        {/* <a
                          href={url}
                          className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                          download={"pdf"}
                        > */}

                        <span
                          className="flex-1 ml-3 whitespace-nowrap capitalize cursor-pointer "
                          onClick={() => download(url)}
                        >
                          download document
                        </span>
                        {/* </a> */}
                      </li>
                    )}
                  </>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </aside>
        </div>
        {data.map((item, index) => {
          const {
            email,
            qualification,
            thumbnail_url,
            user_id,
            Location,
            experience,
            phone,
            name,
          } = item;

          return (
            <div
              className="w-[70%] shadow-lg bg-slate-50  my-6 h-48 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
              key={index}
            >
              <div className="w-full flex items-center justify-center h-full gap-10 ">
                <div className="information flex gap-10">
                  <div>
                    <p>
                      <span className="font-bold"> Name : </span>
                      <span className="font-semibold text-gray-800">
                        {name}
                      </span>
                    </p>

                    <p>
                      <span className="font-bold"> Email : </span>
                      <span className="font-semibold text-gray-800">
                        {email}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className="font-bold"> Qualification : </span>
                      <span className="font-semibold text-gray-800">
                        {qualification}
                      </span>
                    </p>

                    <p>
                      <span className="font-bold"> Experience : </span>
                      <span className="font-semibold text-gray-800">
                        {experience}
                      </span>
                    </p>
                  </div>

                  <div>
                    <p>
                      <span className="font-bold"> Phone : </span>
                      <span className="font-semibold text-gray-800">
                        {phone}
                      </span>
                    </p>

                    <p>
                      <span className="font-bold"> Location : </span>
                      <span className="font-semibold text-gray-800">
                        {Location}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="btn flex gap-2">
                  <button className="border-2 border-gray-100 focus:outline-none bg-purple-600 text-white font-bold tracking-wider block  p-2 rounded-lg focus:border-gray-700 hover:bg-purple-700 w-30  text-center">
                    {Approval}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default UserDashbord;
