import React, { useEffect, useState } from "react";
import { json, Link, useNavigate } from "react-router-dom";
import { sendFormData } from "../../Api";

function DetailsForm() {
  let navigation = useNavigate();
  const [data, setData] = useState({});
  const submit = async (e) => {
    e.preventDefault();

    let res = await sendFormData(data);
    // console.log(res.data);
    if (res) {
      navigation("/");
    }
  };

  const [id, setId] = useState({});
  //   , , description, email,  ;
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
      user_id: id._id,
      // email: id.email,
      name: `${id.firstName} ${id.lastName}`,
    });
  };

  useEffect(() => {
    //    let res localStorage.getItem("loginData");

    setId(JSON.parse(localStorage.getItem("userData")));
  }, []);
  // console.log(id._id);
  // console.log(data);

  useEffect(() => {}, []);

  return (
    <div>
      <section className="min-h-screen flex flex-col">
        <div className="flex flex-1 items-center justify-center">
          <div className="rounded-lg sm:border-2 px-4 lg:px-24 py-16 lg:max-w-xl sm:max-w-md w-full text-center">
            <form className="text-center" onSubmit={submit}>
              <h1 className="font-bold tracking-wider text-3xl mb-8 w-full text-gray-600 capitalize">
                Fill the details
              </h1>
              <div className="py-2 text-left">
                <input
                  type="text"
                  className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700 "
                  placeholder="Qualification"
                  onChange={handleChange}
                  name="qualification"
                  required="true"
                />
              </div>
              <div className="py-2 text-left">
                <input
                  type="text"
                  className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700 "
                  placeholder="Experience in year"
                  onChange={handleChange}
                  name="experience"
                  required="true"
                />
              </div>
              <div className="py-2 text-left">
                <input
                  type="text"
                  className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700 "
                  placeholder="Phone no"
                  onChange={handleChange}
                  name="phone"
                  required="true"
                />
              </div>
              <div className="py-2 text-left">
                <input
                  type="text"
                  required="true"
                  className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700 "
                  placeholder="Location"
                  onChange={handleChange}
                  name="Location"
                />
              </div>
              <div className="py-2 text-left">
                <input
                  type="email"
                  required="true"
                  className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700 "
                  placeholder="email"
                  onChange={handleChange}
                  name="email"
                />
              </div>
              <div className="py-2 text-left">
                <input
                  type="text"
                  required="true"
                  className="bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700 "
                  placeholder="description"
                  onChange={handleChange}
                  name="Location"
                />
              </div>
              <div className="py-2">
                <button
                  type="submit"
                  className="border-2 border-gray-100 focus:outline-none bg-purple-600 text-white font-bold tracking-wider block w-full p-2 rounded-lg focus:border-gray-700 hover:bg-purple-700"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DetailsForm;
