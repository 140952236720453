import axios from "axios";
import { json } from "react-router-dom";
let api = "http://localhost:8000/";
// /registration/signup
const register = async (data) => {
  let response = await axios.post(`${api}registration/signup`, data);
  localStorage.setItem("userData", JSON.stringify(response.data));
  return response;
};
//  "/dashboard/user/upload",
//   upload.single("thumbnail"),

const login = async (data) => {
  let response = await axios.post(`${api}registration/login`, data);
  // console.log(response);
  localStorage.setItem("userData", JSON.stringify(response.data));
  return response;
};
const sendFormData = async (data) => {
  // localStorage.setItem("formData", JSON.stringify(data));
  // console.log(data);
  return await axios.post(`${api}registration/details`, data);
};

// /dashboard/user/view/:id
const getAllUsersDetails = async (data) => {
  return await axios.get(`${api}dashbaord/admin`);
};

const user_adminDetails = async (id) => {
  return await axios.get(`${api}dashboard/user/view/${id}`);
};
const addResume = async (data) => {
  let id = JSON.parse(localStorage.getItem("userData"))._id;

  return await axios.post(`${api}dashboard/user/upload/${id}`, data);
};

const applicationStatus = async (data, id) => {
  // let id = JSON.parse(localStorage.getItem("userData"))._id;
  return await axios.post(`${api}dashboard/admin/user/${id}`, {
    status: data ? "confirmed" : "rejected",
  });
};
// /

const logedUserDetails = async (id) => {
  // let id = JSON.parse(localStorage.getItem("userData"))._id;
  return await axios.get(`${api}dashboard/user/${id}`);
};

export {
  register,
  login,
  sendFormData,
  getAllUsersDetails,
  user_adminDetails,
  addResume,
  applicationStatus,
  logedUserDetails,
};
