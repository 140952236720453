// import Banner from "./Component/Banner";
// import Home from "./Component/Home";
// import NavBar from "./Component/NavBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashbord from "./Component/Dashbord";
import DetailsForm from "./Component/DetailsForm";
import Login from "./Component/Login";
import Resume from "./Component/Resume";
import Signup from "./Component/Signup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserDashbord from "./Component/User-Dashbord";

// import WebDev from "./Component/Pages/WebDev";
// import DigitalMarketing from "./Component/Pages/DigitalMarketing";
// import Remote_employe from "./Component/Pages/Remote_employe";
// import Doc from "./Component/Pages/Rough/doc";

function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/sign-up" element={<Signup />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/details-form" element={<DetailsForm />} />
          <Route path="/dashbord" element={<Dashbord />} />
          <Route path="/user-dashbord" element={<UserDashbord />} />
          {/* 
          <Route path="/services/web_dev" element={<WebDev />} />
          <Route
            path="/services/digital_marketing"
            element={<DigitalMarketing />}
          />
          <Route path="/services/Remote_employe" element={<Remote_employe />} />
          <Route path="/doc" element={<Doc />} /> */}
        </Routes>
      </BrowserRouter>
      ,
    </>
  );
}

export default App;
